import React from 'react';
import PropTypes from 'prop-types';

function ErrorContact(props) {
    const { className: classNameProp, subject } = props;
    return (
        <>
            {' '}
            contact{' '}
            <a
                href={'mailto:support@smartdeploy.com?subject=Customer Portal - ' + subject}
                className={classNameProp}
            >
                support@smartdeploy.com
            </a>{' '}
            if the problem persists
        </>
    );
}

ErrorContact.propTypes = {
    className: PropTypes.string,
    subject: PropTypes.string
};

ErrorContact.defaultProps = {
    subject: 'Error'
};

export default ErrorContact;
