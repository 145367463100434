const prod = window.env.REG_API_DOMAIN;
const prodCamp = window.env.APP_API_DOMAIN;

const getDynamicApi = (env) => prodCamp;

const getDynamicRegistrationApi = (env) => prod;

const getLoginSub = (env) => window.env.B2C_SUBDOMAIN;

const getSdDomain = (env) => window.env.SD_DOMAIN;

const getLogin = (env) => {
  const loginSub = getLoginSub(env);
  return `https://${loginSub}.b2clogin.com/${loginSub}.onmicrosoft.com/oauth2/v2.0`;
};

const domain = {
  prod,
  prodCamp,
  SmartDeployCom: window.env.SD_DOMAIN,

  getDynamicApi,
  getDynamicRegistrationApi,
  getLogin,
  getLoginSub,
};

export default domain;
