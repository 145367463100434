import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import { Provider } from 'react-redux';
// import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import App from 'App';
import store from './store2';
import * as serviceWorker from 'serviceWorker';
// updated with react v18 syntax
const container = document.getElementById('root');
const root = createRoot(container);
root.render(
    <Provider store={store}>
        <App />
    </Provider>
)

// This service worker is disabled currently. While service workers can help
// the app run quicker - and work offline - it causes the index.html to be
// cached, unless the app is reloaded.
//
// This can be changed from `unregister` to `register` to re-enable service workers.
serviceWorker.unregister();
