import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import Loader from 'Common/components/Loader';
import RequireAuth from 'routes/RequireAuth';

// THE BELOW IMPORT SHOULD ONLY BE COMMENTED IN IF WE ARE TESTING THE EMBEDDED COMPONENTS
const Register = lazy(() => import('pages/Register'));
const SignIn = lazy(() => import('pages/SignIn'));
const ForgotPassword = lazy(() => import('pages/ForgotPassword'));
const NewPassword = lazy(() => import('pages/NewPassword'));
const Verification = lazy(() => import('pages/Verification'));
const NotFound = lazy(() => import('pages/NotFound'));
const Error = lazy(() => import('pages/Error'));
const AccountCreated = lazy(() => import('pages/AccountCreated'));
const CreateCompany = lazy(() => import('pages/CreateCompany'));
const JoinCompany = lazy(() => import('pages/JoinCompany'));
const ThankYou = lazy(() => import('pages/ThankYou'));

function MainRoutes() {
    return (
        <Router>
            <Suspense fallback={<Loader loading={true} isPageLoader />}>
                <Routes>
                    <Route path="/" element={<Register />} />
                    <Route path="/signin/" element={<SignIn />} />
                    <Route path="/forgot-password/" element={<ForgotPassword />} />
                    <Route path="/new-password/" element={<NewPassword />} />
                    <Route path="/verification/:userToken/" element={<Verification />} />
                    <Route
                        path="/accountcreated/"
                        element={
                            <RequireAuth>
                                <AccountCreated />
                            </RequireAuth>
                        }
                    />
                    <Route
                        path="/create-company/"
                        element={
                            <RequireAuth>
                                <CreateCompany />
                            </RequireAuth>
                        }
                    />
                    <Route
                        path="/join-company/"
                        element={
                            <RequireAuth>
                                <JoinCompany />
                            </RequireAuth>
                        }
                    />
                    <Route path="/thank-you/" element={<ThankYou />} />
                    <Route path="/error/" element={<Error />} />
                    <Route element={<NotFound />} />
                </Routes>
            </Suspense>
        </Router>
    );
}

export default MainRoutes;
