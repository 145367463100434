import React, { useState, useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import checkIfRestricted from './scripts/check-if-restricted';
import { useRefreshMutation } from 'features/apiSlice';

// todo: can we also check for an expired token here?

export default function RequireAuth({ children }) {
    const [state, setState] = useState(null);
    const location = useLocation();
    const [refresh, { isLoading }] = useRefreshMutation();

    useEffect(() => {
        checkIfRestricted(refresh, isLoading, location?.pathname, location.state?.referrer).then(
            data => {
                setState(data);
            }
        );
    }, []);

    if (!state) {
        return null;
    }

    return state.path ? (
        <Navigate to={state.path} state={{ redirectMessage: state.message }} />
    ) : (
        children
    );
}
