import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import Routes from 'Routes';
import { theme } from 'Common/styles/theme';
import 'services/fontAwesome';
import 'styles/global-styles.scss';

export default function App() {
    return <ThemeProvider theme={theme}><Routes /></ThemeProvider>;
}
