import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './styles.module.scss';

function Loader(props) {
  const {
    loading,
    isPageLoader,
    isComponentLoader,
    isComponentLoaderNoBackground,
    isGray,
    className: classNameProp,
    ...rest
  } = props;

  const mainClasses = classNames(classNameProp, {
    [styles.pageLoader]: isPageLoader,
    [styles.componentLoader]: isComponentLoader,
    [styles.componentLoaderNoBackground]: isComponentLoaderNoBackground,
    [styles.isGray]: isGray,
    [styles.loaderIsLoading]: loading,
  });

  return (
    <div className={mainClasses} data-testid='loader' {...rest}>
      <div className={styles.loaderAnimation}>
        <div className={styles.loaderAnimationLine} />
        <div className={styles.loaderAnimationLine} />
        <div className={styles.loaderAnimationLine} />
        <div className={styles.loaderAnimationLine} />
      </div>
    </div>
  );
}

Loader.propTypes = {
  loading: PropTypes.bool,
  /** use this if the loader fills a whole page */
  isPageLoader: PropTypes.bool,
  /** use this if the loader covers a component */
  isComponentLoader: PropTypes.bool,
  /** use this if the loader is contained in a component */
  isComponentLoaderNoBackground: PropTypes.bool,
  // ** use this for components on a gray background */
  isGray: PropTypes.bool,
  className: PropTypes.string,
};

Loader.defaultProps = {
  loading: true,
  isPageLoader: false,
  isComponentLoader: false,
  isComponentLoaderNoBackground: false,
  isGray: false,
  className: null,
};

export default Loader;
