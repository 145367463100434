import jwt_decode from "jwt-decode";
import session from 'Common/scripts/session';
import MessageAndPath from 'services/message-and-path';

// this file is similar to \pages\SignIn\scripts\get-signin-redirect.js fwiw
const routeToCorrectPage = (currentPath, referrer, registrationStatus) => {
    // join or create company pages shouldn't be directly accessible
    // users should only arrive on them after selecting a link on accountcreated
    if (
        (currentPath === '/join-company/' || currentPath === '/create-company/') &&
        referrer !== '/accountcreated/'
    ) {
        return new MessageAndPath('Please make a selection.', '/accountcreated/');
    }
    switch (registrationStatus) {
        case 0:
            return new MessageAndPath('Please create an account.', '/');
        case 5:
        case 6:
        case 3:
        case 4:
        case 7:
        case 8:
        case 9:
            // go to the requested page page
            return {};
        default:
            return new MessageAndPath('Please sign in.', '/signin/');
    }
};
const checkIfRestricted = async (refresh, isLoading, currentPath, referrer = null) => {
    const sess = session.getUserData();

    // if we have data in the session
    if (Object.values(sess).length > 0) {
        const idToken = sess.idToken;
        const registrationStatus = idToken.extension_userRegistrationStatus;
        const expirationTime = idToken.exp * 1000;
        const timeNow = new Date().getTime();

        // check if the token is expired
        if (timeNow >= expirationTime) {
            // return the results so the info below makes it back up to the Route component
            try {
                const response = await refresh().unwrap();
                if (response.redirectUrl) {
                    return new MessageAndPath(null, response.redirectUrl);
                }
                // success case things
                const state = {
                    idToken: jwt_decode(response.IdToken),
                    refreshToken: response.RefreshToken,
                    accessToken: response.AccessToken
                };
                session.storeUserData(state, true)
                return routeToCorrectPage(currentPath, referrer, registrationStatus);
            } catch (err) {
                // todo: ?
            }
        } else {
            return routeToCorrectPage(currentPath, referrer, registrationStatus);
        }
    } else {
        // if there is no session do this
        return new MessageAndPath('Please sign in.', '/signin/');
    }
};
export default checkIfRestricted;
