import { configureStore } from '@reduxjs/toolkit';
import { apiSlice } from './features/apiSlice';
import errorCatchingMiddleware from './features/errorCatchingMiddleware';

export default configureStore({
    reducer: {
        [apiSlice.reducerPath]: apiSlice.reducer
    },

    middleware: getDefaultMiddleware =>
        getDefaultMiddleware().concat(
            errorCatchingMiddleware,
            apiSlice.middleware
        )
    // getDefaultMiddleware().concat(errorCatchingMiddleware, apiSlice.middleware)
});
