import { createTheme } from '@mui/material';

const theme = createTheme({
  palette: {
    common: {
      black: '#595555',
      white: '#efefef',
    },
    primary: {
      main: '#635BFF',
      dark: '#333060',
      contrastText: '#fff',
    },
    secondary: {
      main: '#848280',
      contrastText: '#fff',
    },
    error: {
      main: '#db2126',
      contrastText: '#fff',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          textTransform: 'none',
          ...(ownerState.size === 'large' && {
            fontSize: 14,
          }),
        }),
      },
    },
    MuiLink: {
      styleOverrides: {
        root: ({ theme }) => ({
          textDecoration: 'none',
          ':hover': {
            textDecoration: 'underline',
            color: theme.palette.primary.dark,
          },
        }),
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          width: '100%',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          ...(ownerState.variant === 'h1' && {
            fontSize: 25,
            fontWeight: theme.typography.fontWeightMedium,
          }),
          ...(ownerState.variant === 'subtitle1' && {
            fontSize: 14,
            color: theme.palette.secondary.main,
            fontWeight: theme.typography.fontWeightMedium,
          }),
        }),
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          backgroundColor: '#FFFFFF',
          width: '100%',
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: ({ theme }) => ({
          flexGrow: 1,
          margin: 'auto',
          maxWidth: '465px',
          padding: 16,
          boxShadow: theme.shadows[6],
        }),
      },
    },
  },
});

export { theme };
