import { library } from '@fortawesome/fontawesome-svg-core';
import { faEye, faEyeSlash } from '@fortawesome/free-regular-svg-icons';
import {
    faChevronRight,
    faFilm,
    faComments,
    faUsers,
    faCheckCircle
} from '@fortawesome/free-solid-svg-icons';

const icons = [faEye, faEyeSlash, faChevronRight, faComments, faFilm, faUsers, faCheckCircle];

const registerIcons = () => {
    icons.forEach(icon => library.add(icon));
};

registerIcons();

export default registerIcons;
