import React from 'react';
import ErrorContact from '../ErrorContact';

const ConnectionError = () => (
  <>
    There was an error retrieving your information. Please reload the page or{' '}
    <ErrorContact subject='API Connection Error' />.
  </>
);

export default ConnectionError;
